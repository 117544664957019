import React, { useState } from 'react';

function StartGameModal(props) {
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  function handlePlayerSelect(player) {
    setSelectedPlayer(player);
  }

  function handleSubmit(event) {
    event.preventDefault();
    props.onPlayerSelect(selectedPlayer);
  }

  return (
    <div className="modal">
      <h2>選擇你的角色</h2>
      <form onSubmit={handleSubmit}>
        <div className="player-select">
          <button
            className={`player-select-btn ${selectedPlayer === 'X' ? 'selected' : ''}`}
            onClick={() => handlePlayerSelect('X')} style={{fontSize:77, border: 'none'}}
          >
            ⭐️
          </button>
          <button
            className={`player-select-btn ${selectedPlayer === 'O' ? 'selected' : ''}`}
            onClick={() => handlePlayerSelect('O')} style={{fontSize:77, border: 'none'}}
          >
            🌎
          </button>
        </div>
        <button type="submit" disabled={!selectedPlayer}>
        開始遊戲
        </button>
      </form>
    </div>
  );
}

export default StartGameModal;
